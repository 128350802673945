<template>
  <q-dialog
    v-model="isOpen"
    :maximized="false"
  >
    <q-card
      v-if="isLoading"
      class="row items-center justify-center"
      style="min-width: 50vw; min-height: 300px;"
    >
      <div class="text-center">
        <q-spinner
          color="primary"
          size="4rem"
          class="q-mb-md"
        />

        <div class="text-center text-subtitle1 text-bold q-py-sm">
          {{ `${loading} ${$t('of')} ${totalLoadings}` }}
        </div>
      </div>
    </q-card>

    <q-card
      v-else
      class="relative"
      style="min-width: 50vh; max-width: 100vw;"
    >
      <q-card-section class="row items-center q-pb-none">
        <q-space />

        <q-btn
          text-color="dark"
          size="sm"
          icon="close"
          no-caps
          flat
          unelevated
          @click="close"
        />
      </q-card-section>

      <q-card-section>
        <div class="text-center">
          <q-btn
            :label="$t('Browse file on your computer')"
            size="md"
            style="min-height: 5rem;"
            color="light-blue-9"
            no-caps
            @click="handleBrowse"
          />

          <div class="row items-center justify-center q-my-md">
            <div
              style="text-decoration: underline;"
              class="clickable text-dark q-pa-md"
              @click="getExampleTable"
            >
              {{ $t('Download template') }}
            </div>

            <a
              :href="$instructions.acceptance.import"
              class="clickable text-dark q-pa-md"
              target="blank"
            >
              {{ $t('Read instructions') }}
            </a>
          </div>
        </div>
      </q-card-section>
    </q-card>

    <confirm-modal ref="confirm" />

    <q-file
      ref="fileField"
      filled
      class="d-none"
      style="min-width: 200px;"
      :label="$t('Pick File')"
      @update:model-value="handleTable"
    />
  </q-dialog>
</template>

<script>
// Vuex
import { mapMutations } from 'vuex'

// Components
import ConfirmModal from '../confirm-modal/ConfirmModal.vue'

export default {
  name: 'AcceptanceImportModal',
  components: {
    ConfirmModal
  },
  data () {
    return {
      isOpen: false,
      totalLoadings: 0,
      loading: 0,
      isLoading: false
    }
  },
  methods: {
    ...mapMutations([
      'setAcceptanceItems',
      'addErrorNotification'
    ]),
    open () {
      this.isOpen = true
    },
    close () {
      this.isOpen = false
    },
    handleTable (file) {
      if (!file) {
        return
      }

      return this.$service.import.loadFile(file, 'items')
        .then(result => {
          this.totalLoadings = result.length
          this.loading = 1
          this.isLoading = true

          return this.$service.import.loadOffersFromAcceptanceItems(
            result,
            () => {
              this.loading += 1
            }
          )
        })
        .then(({ results, errors }) => {
          this.totalLoadings = 0
          this.loading = 0
          this.isLoading = false

          if (errors.length <= 0) {
            return { results, isOk: true }
          }

          const options = {
            description: this.$t('We found errors in some products. Do you want to continue without them?'),
            list: errors.map(text => {
              return {
                styleClasses: 'bg-negative text-white',
                text
              }
            })
          }

          return this.$refs.confirm.show(options).then(isOk => ({ results, isOk }))
        })
        .then(({ isOk, results}) => {
          if (!isOk) {
            return
          }

          this.setAcceptanceItems(results.map(x => ({ ...x, state: 'new' })))
          this.$router.push('/inbound/purchase-orders/entity/unknown?hasItems=true')
        })
        .catch(err => {
          this.isLoading = false
          this.addErrorNotification(err)
        })
    },
    getExampleTable () {
      return this.$service.import.getAcceptanceItemsExample()
    },
    handleBrowse () {
      this.$refs.fileField.pickFiles()
    }
  }
}
</script>
