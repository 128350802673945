<template>
  <q-dialog
    v-model="isOpen"
    persistent
    :class="`modal-${size}`"
  >
    <q-card>
      <q-card-section class="text-center text-h6">
        {{ options.description }}
      </q-card-section>

      <q-card-section v-if="options.list" class="text-center text-h6">
        <div
          v-for="(item, i) in options.list"
          :key="item.id || i"
          class="q-pa-sm q-ma-sm rounded"
          :class="item.styleClasses || ''"
        >
          {{ item.text }}
        </div>
      </q-card-section>

      <q-card-actions align="center">

        <q-btn
            :color="options.okColor || 'positive'"
            :label="options.agree"
            no-caps
            @click="handleOk"
        />

        <q-btn
          v-if="!options.noCancel"
          color="negative"
          :label="options.disagree"
          no-caps
          @click="handleClose"
        />

      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: 'ConfirmModal',
  props: {
    size: {
      type: String,
      default () {
        return 'sm'
      }
    }
  },
  data () {
    return {
      isOpen: false,
      options: {
        description: '',
        disagree: 'Cancel',
        agree: 'Ok'
      }
    }
  },
  methods: {
    resolve () {},
    reject () {},
    show (options = {}) {
      this.options = {
        ...this.options,
        ...options
      }
      this.isOpen = true

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    handleClose () {
      this.isOpen = false
      this.resolve(false)
    },
    handleOk () {
      this.isOpen = false
      this.resolve(true)
    }
  }
}
</script>
