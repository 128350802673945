<template>
  <div class="q-pa-md">
    <q-card>
      <q-card-section
          class="row border-bottom items-center full-width q-py-xs q-pl-none"
      >
        <q-legend :label="$t('Purchase orders')" text-class="text-h6"/>

        <search dense autoset is-expandable @submit="handleSearch"/>

        <q-space/>
        <q-btn
            :color="items.length > 0 ? 'amber-6' : 'dark'"
            text-color="white"
            :label="$t('Add to an order') + `${items.length > 0 ? ` (${items.length})` : ''}`"
            class="q-mr-sm"
            size="sm"
            no-caps
            unelevated
            :disable="!hasBaseAccess"
            @click="handleAddToOrder"
        />

        <q-btn
            :color="
            serverParams.filter && serverParams.filter.length > 0
              ? 'light-blue-9'
              : 'dark'
          "
            text-color="white"
            size="sm"
            class="q-mr-sm"
            :label="filterBtnText"
            no-caps
            unelevated
            @click="openCloseFilters"
        />

        <q-btn
            color="dark"
            text-color="white"
            :label="$t('Refresh')"
            size="sm"
            class="q-mr-sm"
            no-caps
            unelevated
            @click="refreshItems"
        />

        <q-btn
            color="positive"
            text-color="white"
            :label="$t('Import')"
            class="q-mr-sm"
            size="sm"
            no-caps
            unelevated
            @click="handleImport"
        />

        <q-btn
            color="orange"
            text-color="white"
            :label="`(${acceptances.length}) ${$t('Export to excel')}`"
            class="q-mr-sm"
            size="sm"
            no-caps
            unelevated
            @click="downloadExcel"
        />

        <q-btn color="light-blue-9" size="sm" icon="add" :label="$t('Inbound')"
               @click="createAcceptance('products')"></q-btn>
      </q-card-section>

      <q-card-section class="q-ma-none q-pa-none">
        <filters-collapse
            :is-open="isOpenFilters"
            :options="{
            defaultFilter: serverParams.filter,
            fields: activatedFields,
            values: {
              states: states,
              types: types,
            },
          }"
            @submit="handleFiltersSubmit"
            @close="openCloseFilters"
        />
      </q-card-section>

      <q-card-section class="row q-ma-none q-pa-none">
        <status-filter
                class="hide-on-mobile"
                :outside-selected-status="selectedStatusFilter"
                :statuses="acceptanceStatuses"
                @on-change="onStatusFilter"
        />
        <div class="col">
          <q-table
              style="height: calc(100vh - 130px)"
              class="sticky-header-table"
              row-key="id"
              :rows-per-page-label="$t('Rows per page')"
              :rows="acceptances"
              :columns="columns"
              v-model:pagination="pagination"
              :loading="acceptancesLoading"
              :filter="filter"
              :rows-per-page-options="[0, 25, 50, 100, 150, 200, 250 , 0]"
              v-model:selected="items"
              virtual-scroll
              selection="multiple"
              binary-state-sort
              flat
              @request="onRequest"
          >
            <template v-slot:header-selection="props">
              <q-checkbox
                  size="xl"
                  :model-value="items.length > 0"
                  color="amber-6"
                  @click="handleSelectAll(props)"
              />
            </template>

            <template v-slot:loading>
              <q-inner-loading showing color="primary"/>
            </template>

            <template v-slot:body="props">
              <acceptance-row
                  :data="props"
                  :items="items"
                  @on-dblclick="onRowDblClick"
                  @check="handleCheck"/>
            </template>
          </q-table>
        </div>
      </q-card-section>
    </q-card>
    <order-acceptance-modal ref="orderModal" :warehouse="choosenWarehouse"/>
    <acceptance-import-modal ref="acceptanceImportModal"/>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Components
import Search from '../../components/search/Search.vue'
import FiltersCollapse from '../../components/filters/FilterCollapse'
import AcceptanceImportModal from '../../components/imports/AcceptanceImportModal.vue'
import AcceptanceRow from '../../components/tables/AcceptanceRow.vue'
import OrderAcceptanceModal from '../../components/modals/OrderAcceptanceModal.vue'

// Utils
import { buildQuery } from '../../utils/query-utils'

// Mixins
import TableMixin from './../../components/global/TableMixin'
import _ from 'lodash'
import StatusFilter from "./../../components/filters/StatusFilter.vue";

export default {
  name: 'Acceptances',
  components: {
    StatusFilter,
    Search,
    FiltersCollapse,
    AcceptanceImportModal,
    AcceptanceRow,
    OrderAcceptanceModal,
  },
  mixins: [
    TableMixin
  ],
  data () {
    return {
      isOpenFilters: false,
      filter: '',
      items: [],
      products: [],
      updatedProducts: {},
      isProductsReady: false,
      choosenWarehouse: {},
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      columns: [
        {
          label: '#',
          name: 'number',
          align: 'left'
        },
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left'
        },
        {
          label: this.$t('Warehouse'),
          name: 'storage',
          align: 'left'
        },
        {
          label: this.$t('Created'),
          name: 'created',
          align: 'left'
        },
        {
          label: this.$t('Comment'),
          name: 'comment',
          align: 'left'
        },
        {
          label: this.$t('Download'),
          name: 'action',
          align: 'center'
        }
      ],
      types: [
        { id: 'products', title: 'Products' },
        { id: 'items', title: 'Items' },
        { id: 'palletized', title: 'Palletized' },
        { id: 'return', title: 'Return' }
      ],
      acceptanceStatuses:  [
        {
        id: 1,
        group: 'Default',
        color: 'grey-5',
        buttons: [
          {
            id: 'new',
            title: 'New',
            color: 'info',
            editableField: false,
            transitions: []
          },
          {
            id: 'confirmed',
            title: 'Confirmed',
            color: 'yellow',
            editableField: false,
            transitions: []
          },
          {
            id: 'closed',
            title: 'Closed',
            color: 'dark text-white',
            editableField: false,
            transitions: []
          },
          {
            id: 'draft',
            title: 'Draft',
            color: 'info',
            editableField: false,
            transitions: []
          },
          {
            id: 'cancel',
            title: 'Cancel',
            color: 'danger',
            editableField: false,
            transitions: []
          }
          ]
        },
      ],
      states: [
        { id: 'checking', title: 'Checking' },
        { id: 'new', title: 'New' },
        { id: 'confirmed', title: 'Confirmed' }
      ],
      activatedFields: [
        'id',
        'extId',
        'state',
        'type',
        'warehouse',
        'updated.from',
        'updated.to',
        'created.from',
        'created.to',
        'extDate.from',
        'extDate.to'
      ]
    }
  },
  computed: {
    ...mapGetters([
      'acceptancePage',
      'acceptances',
      'totalAcceptanceNumber',
      'acceptancesLoading',
      'appOptions',
      'hasBaseAccess'
    ]),
    selectedStatusFilter () {
      const status = (this.serverParams.filter || []).find(filter => {
        return filter.field === 'state'
      })
      if (status && status.values) {
        return status.values
      }
      return status
        ? [status.value]
        : []
    },
    filterBtnText () {
      return this.serverParams.filter && this.serverParams.filter.length > 0
          ? this.$t('Filtered: ' + this.totalAcceptanceNumber)
          : this.$t('Filter')
    }
  },
  mounted () {
    this.loadDefaultItems()
  },
  methods: {
    ...mapActions([
      'loadAcceptances'
    ]),
    ...mapMutations([
      'setAcceptance',
      'setAcceptanceItems',
      'addWarningNotification',
      'addErrorNotification'
    ]),
    onStatusFilter (values) {
      const query = {
        per_page: this.serverParams.perPage || 25,
        page: 1,
        'order-by': [
          {
            type: 'field',
            field: 'created',
            direction: 'desc'
          }
        ],
        filter: [
          ...(this.serverParams.filter || [])
        ]
      }

      query.filter = query.filter.filter(val => {
        return val.type !== 'in' && val.field !== 'state'
      })

      if (values.length > 0) {
        query.filter.push({ type: 'in', field: 'state', values })
      }

      this.updateParams(query)
      return this.loadAcceptances(query)
        .then(({ page, totalItems }) => {
          this.pagination = {
            ...this.pagination,
            page,
            rowsNumber: totalItems
          }
        })
    },
    refreshItems () {
      return this.onRequest({
        pagination: {
          forceReload: true
        }
      })
    },
    handleImport () {
      this.$refs.acceptanceImportModal.open()
    },
    handleFiltersSubmit (filter) {
      this.isOpenFilters = false
      return this.onRequest({ pagination: { filter, page: 1 } })
    },
    openCloseFilters () {
      this.isOpenFilters = !this.isOpenFilters
    },
    handleSearch (search) {
      return this.onRequest({ pagination: { page: 1, search } })
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      this.updateParams(query)

      return this.loadAcceptances(this.serverParams)
          .then(({ page, totalItems }) => {
            this.pagination = {
              ...this.pagination,
              page,
              rowsNumber: totalItems
            }
          })
    },
    loadDefaultItems () {
      return this.onRequest({ pagination: {} })
    },
    createAcceptance (type) {
      this.$router.push(`/inbound/purchase-orders/entity/${type}`)
    },
    onRowDblClick (acceptance) {
      this.setAcceptance(acceptance)
      this.$router.push(`/inbound/purchase-orders/entity/${acceptance.type}/${acceptance.id}`)
    },
    handleSelectAll (props) {
      props.selected = !props.selected
    },
    test2 () {

    },
    handleAddToOrder () {
      const query = {}

      this.isLoading = true
      const idsForSearch = []
      this.items.forEach(e => {
        if (!query.filter) {
          query.filter = []
        }

        idsForSearch.push(e.id)
      })
      query.filter.push({ field: 'document', type: 'in', values: idsForSearch })
      query['per_page'] = 250
      this.$service.acceptanceItem.getAllForAcceptance(query)
          .then(({ items, totalPages, page, totalItems }) => {
            if (totalPages > 1) {
              this.addErrorNotification('Warning too much items . If you want to proceed make a normal order!')
              return
            }
            this.pagination = {
              ...this.pagination,
              // Their is a problem with page in response. It is always 1 so we set it manually
              page: query.page || 1,
              rowsNumber: totalItems
            }

            this.products = this.updatedProducts[page]

                ? items.map(x => {
                  const updatedItem = this.updatedProducts[page].find(i => i.id === x.id && i.originalSku === x.originalSku)
                  return updatedItem || x
                })
                : items
            let merged = []
            this.products.forEach(e => {
              if (!merged.some(el => el?._embedded?.productOffer.id === e?._embedded?.productOffer.id)) {
                if (e._embedded?.document?.state === 'new') {
                  e.count = e.quantityExpected
                } else if (e._embedded?.document?.state === 'confirmed' || e._embedded?.document?.state === 'checking') {
                  e.count = e.quantityReal
                } else {
                  e.count = e.quantityPlace
                }
                merged.push(e)
              } else {
                merged.map(el => {
                  if (el?._embedded?.productOffer.id === e?._embedded?.productOffer.id) {
                    if (el._embedded.document.state === 'new') {
                      el.count += e.quantityExpected
                    } else if (el._embedded.document.state === 'confirmed' || el._embedded.document.state === 'checking') {
                      el.count += e.quantityReal
                    } else {
                      el.count += e.quantityPlace
                    }
                  }
                })
              }
            })
            this.products = merged

            this.setAcceptanceItems(_.cloneDeep(items))
            return { items, totalPages, page, totalItems }
          })
          .finally(() => {
            this.$refs.orderModal.show(this.products.map(item => ({ ...item, count: item.count })))
            this.isLoading = false
          })

      return this.isLoading = true

    },
    handleCheck (item) {
      let hasItem = false
      const test = this.items.some(x => x._embedded.warehouse.id !== item._embedded.warehouse.id)
      this.choosenWarehouse = item._embedded.warehouse
      if (test) {
        this.addWarningNotification('The acceptance should be with the same warehouse')
        return false
      }
      this.items = this.items.filter(x => {
        if (x.id === item.id) {
          hasItem = true
          return false
        }

        return true
      })

      if (!hasItem) {
        this.items = [item, ...this.items]
      }
    },
    downloadExcel () {
      const query = buildQuery(this.pagination)
      query.filter = this.serverParams.filter

      return this.$service.acceptance.downloadAll(query)
    },
  }
}
</script>
